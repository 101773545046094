<script setup>
import { computed } from 'vue';
import { KudoCard, ScaleModule, InputError, ScaleModuleResponse, Icons } from '@goodvibes/kudoslink';
import { CheckIcon } from '@heroicons/vue/24/outline';
import { useForm, usePage } from '@inertiajs/vue3';
import { useToast, POSITION } from 'vue-toastification';
import VotedMessage from '../VotedMessage.vue';

/**
 * @type {{ meeting: Meeting, poll: Poll, responses: Array<Rating>, anonymousId: string, name: string }}
 */
const props = defineProps({
    meeting: {
        type: Object,
        required: true,
    },
    poll: {
        type: Object,
        required: true,
    },
    responses: {
        type: Array,
        required: true,
    },
    // Anonymous user identifier
    anonymousId: {
        type: String,
        required: true,
    },
    name: {
        type: String,
        required: false,
    },
});

const toast = useToast();

const form = useForm({
    rating: null,
});

const hasVoted = computed(() => props.responses.some(response => response.anonymousId === props.anonymousId));

function save() {
    form.post(route('responses.ratings.store', { uuid: props.meeting.uuid, pollId: props.poll.id }), {
        headers: {
            // this will help Laravel identify, who is the sender, and it will use that to not send the event to this user
            'X-Socket-ID': window.Echo.socketId(),
        },
        preserveScroll: true,
        onSuccess: () => {
            const { type = 'success', content = 'Rating submitted!' } = usePage().props.flash.notification || {};
            toast[type](content, { position: POSITION.BOTTOM_CENTER });

            form.reset();
        },
    });
}
</script>

<template>
    <VotedMessage v-if="hasVoted" />
    <form v-else-if="!poll.closedAt" @submit.prevent="save">
        <ScaleModule
            :scaleType="poll.settings.scaleUnit"
            :minScale="+poll.settings.min"
            :maxScale="+poll.settings.max"
            :textLeft="poll.settings.minLabel"
            :textRight="poll.settings.maxLabel"
            v-model:answer="form.rating"
        />

        <InputError v-if="form.errors.rating" :errors="[form.errors.rating]" />

        <div class="flex justify-center lg:justify-end">
            <button
                class="btn flex btn--narrow text-[#ffff] bg-[#6212E5] hover:bg-[#7E39EF] mt-10 mb-10"
                type="submit"
                :disabled="form.processing"
                v-text="form.processing ? 'Submitting...' : 'Submit'"
            ></button>
        </div>
    </form>
    <p v-else class="text-[#8C96A1] italic text-center">Voting was stopped.</p>

    <template v-if="poll.showResults">
        <div class="flex items-center text-[#8C96A1] font-semibold text-base leading-7 mt-12 mb-10">
            <h2>Live results</h2>
            <Icons.PersonCheckIcon class="ml-5 w-5 inline-block" />
            <span class="ml-2">{{ poll.responsesCount }}</span>
        </div>
        <ScaleModuleResponse
            :answers="responses.map(r => r.rating)"
            :answered="responses.length"
            :minScale="+poll.settings.min"
            :maxScale="+poll.settings.max"
            :scaleType="poll.settings.scaleUnit"
            class="text-[0.6rem] sm:text-xs lg:text-lg"
        />
    </template>
</template>
