<script setup>
import { InputError, KudoCard, MultipleChoiceModule, MultipleChoiceModuleResponse, Icons } from '@goodvibes/kudoslink';
import { CheckIcon } from '@heroicons/vue/24/outline';
import { useForm, usePage } from '@inertiajs/vue3';
import { computed } from 'vue';
import { useToast, POSITION } from 'vue-toastification';
import VotedMessage from '../VotedMessage.vue';

/**
 * @type {{ meeting: Meeting, poll: Poll, responses: Array<MultipleChoice>, anonymousId: string, name: string }}
 */
const props = defineProps({
    meeting: {
        type: Object,
        required: true,
    },
    poll: {
        type: Object,
        required: true,
    },
    responses: {
        type: Array,
        required: true,
    },
    // Anonymous user identifier
    anonymousId: {
        type: String,
        required: true,
    },
    name: {
        type: String,
        required: false,
    },
});

const toast = useToast();

const form = useForm({
    answers: props.poll.settings.multipleChoice ? [] : '',
});

const hasVoted = computed(() => props.responses.some(response => response.anonymousId === props.anonymousId));

function save() {
    form.post(route('responses.multipleChoices.store', { uuid: props.meeting.uuid, pollId: props.poll.id }), {
        headers: {
            // this will help Laravel identify, who is the sender, and it will use that to not send the event to this user
            'X-Socket-ID': window.Echo.socketId(),
        },
        preserveScroll: true,
        onSuccess: () => {
            const { type = 'success', content = 'Answer submitted!' } = usePage().props.flash.notification || {};
            toast[type](content, { position: POSITION.BOTTOM_CENTER });

            form.reset();
        },
    });
}
</script>

<template>
    <VotedMessage v-if="hasVoted" />
    <form @submit.prevent="save" v-else-if="!poll.closedAt">
        <div class="mt-10">
            <MultipleChoiceModule
                :choices="poll.choices"
                v-model:answer="form.answers"
                :allowAddingOwnAnswers="poll.settings.customAnswers"
            />
            <InputError v-if="form.errors.answers" :errors="[form.errors.answers]" />
        </div>
        <div class="flex justify-center lg:justify-end">
            <button
                class="btn flex btn--narrow text-[#ffff] bg-[#6212E5] hover:bg-[#7E39EF] mt-10 mb-10"
                type="submit"
                :disabled="form.processing"
                v-text="form.processing ? 'Submitting...' : 'Submit'"
            ></button>
        </div>
    </form>
    <p v-else class="text-[#8C96A1] italic text-center">Voting was stopped.</p>
    <template v-if="poll.showResults">
        <div class="flex items-center text-[#8C96A1] font-semibold text-base leading-7 mt-12 mb-10">
            <h2>Live results</h2>
            <Icons.PersonCheckIcon class="ml-5 w-5 inline-block" />
            <span class="ml-2">{{ poll.responsesCount }}</span>
        </div>
        <MultipleChoiceModuleResponse
            :answers="responses.map(response => response.answers).flat()"
            :choices="poll.choices.map(choice => choice.option)"
            resultsListClasses="max-h-none"
        />
    </template>
</template>
