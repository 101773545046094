<script setup>
import { Icons, ModalDialog, PopperTooltip } from '@goodvibes/kudoslink';
import { useForm, router, usePage } from '@inertiajs/vue3';
import { PencilIcon, TrashIcon } from '@heroicons/vue/24/outline';
import { ref, computed } from 'vue';
import { useToast, POSITION } from 'vue-toastification';
import ConfirmationDialog from '@/Components/ConfirmationDialog.vue';
import { QuestionCard, QuestionForm } from '@/Components';
import { OpenText } from '@/models';
import { vInfiniteScroll } from '@vueuse/components';

/**
 * @type {{ meeting: Meeting, poll: Poll, openTexts: Array<OpenText>, anonymousId: string, name: string }}
 */
const props = defineProps({
    meeting: {
        type: Object,
        required: true,
    },
    poll: {
        type: Object,
        required: true,
    },
    openTexts: {
        type: Array,
        required: true,
    },
    // Anonymous user identifier
    anonymousId: {
        type: String,
        required: true,
    },
    name: {
        type: String,
        required: false,
    },
});

const emit = defineEmits(['loadMore']);

const toast = useToast();

const form = useForm({
    message: '',
    name: props.name,
    pollId: props.poll.id,
});

const openTexts = computed(() => OpenText.sort(props.openTexts, 'createdAt', 'desc'));

const openTextToUpdate = ref();
function openEditDialog(openText) {
    const o = props.openTexts.find(o => o.id === openText.id);
    form.message = o.message;
    form.name = o.name;
    openTextToUpdate.value = openText;
}

function closeEditDialog() {
    form.reset();
    openTextToUpdate.value = null;
}

function save() {
    form.post(route('responses.openTexts.store', { uuid: props.meeting.uuid, pollId: props.poll.id }), {
        headers: {
            // this will help Laravel identify, who is the sender, and it will use that to not send the event to this user
            'X-Socket-ID': window.Echo.socketId(),
        },
        preserveScroll: true,
        onSuccess: () => {
            const { type = 'success', content = 'Open text successfully sent!' } =
                usePage().props.flash.notification || {};
            toast[type](content, { position: POSITION.BOTTOM_CENTER });
            form.reset('message');
        },
    });
}

function update() {
    form.patch(
        route('responses.openTexts.update', {
            uuid: props.meeting.uuid,
            pollId: props.poll.id,
            openText: openTextToUpdate.value.id,
        }),
        {
            preserveScroll: true,
            onSuccess: () => {
                openTextToUpdate.value = null;
                const { type = 'success', content = 'Open text updated!' } = usePage().props.flash.notification || {};
                toast[type](content, { position: POSITION.BOTTOM_CENTER });
                form.reset('message');
            },
        },
    );
}

const deleteOpenTextDialog = ref();

async function deleteOpenText(openText) {
    const { confirmed } = await deleteOpenTextDialog.value.open();
    if (!confirmed) {
        return;
    }

    router.delete(
        route('responses.openTexts.destroy', {
            uuid: props.meeting.uuid,
            openTextId: openText.id,
            pollId: props.poll.id,
        }),
        {
            preserveScroll: true,
        },
    );
}

function onLoadMore() {
    emit('loadMore');
}
</script>

<template>
    <QuestionForm
        v-if="!poll.closedAt"
        @submit="save"
        v-model:name="form.name"
        v-model:message="form.message"
        :characterLimit="+200"
        :showNameField="poll.settings.showNames ?? null"
        :errors="{
            name: form.errors.name && [form.errors.name],
            message: form.errors.message && [form.errors.message],
        }"
        :messageInputAttrs="{ placeholder: 'Type your answer' }"
    >
        <div class="flex justify-center lg:justify-end">
            <button
                class="btn flex btn--narrow text-[#ffff] bg-[#6212E5] hover:bg-[#7E39EF]"
                type="submit"
                :disabled="form.processing"
                v-text="form.processing ? 'Sending' : 'Submit'"
            ></button>
        </div>
    </QuestionForm>

    <p v-else class="text-[#8C96A1] italic text-center">Open Text was stopped.</p>

    <div class="opentexts mt-20">
        <div class="flex items-center text-[#8C96A1] font-semibold text-base leading-7 mt-12 mb-10">
            <h2>Audience results</h2>
            <Icons.PersonCheckIcon class="ml-5 w-5 inline-block" />
            <span class="ml-2">{{ poll.responsesCount }}</span>
            <div class="h-[1px] grow bg-[#E4EDF5]"></div>
        </div>

        <div class="max-h-96 overflow-y-scroll" v-infinite-scroll="[onLoadMore, { distance: 2 }]">
            <TransitionGroup name="list">
                <QuestionCard
                    v-for="openText in openTexts"
                    :key="openText.id"
                    :author="poll.settings.showNames ? openText.name : null"
                    class="mt-10 transition-all"
                >
                    <div class="flex justify-between items-center">
                        <p v-text="openText.message"></p>
                        <div class="flex gap-x-3">
                            <PopperTooltip
                                v-if="
                                    openText.anonymousId === anonymousId &&
                                    new Date(openText.createdAt.getTime() + 5 * 60000) > new Date()
                                "
                                placement="top"
                            >
                                <div
                                    class="h-10 p-3 cursor-pointer bg-[#EDEDFC] text-[#6212E5] rounded-xl flex items-center justify-between text-xs"
                                    @click="openEditDialog(openText)"
                                >
                                    <PencilIcon class="h-4 stroke-current transition duration-300" />
                                </div>
                                <template #popper>Edit</template>
                            </PopperTooltip>

                            <PopperTooltip
                                v-if="
                                    openText.anonymousId === anonymousId &&
                                    new Date(openText.createdAt.getTime() + 5 * 60000) > new Date()
                                "
                                placement="top"
                            >
                                <div
                                    class="h-10 p-3 cursor-pointer bg-[#EDEDFC] text-[#6212E5] rounded-xl flex items-center justify-between text-xs"
                                    @click="deleteOpenText(openText)"
                                >
                                    <TrashIcon class="h-4 stroke-current transition duration-300" />
                                </div>
                                <template #popper>Delete</template>
                            </PopperTooltip>
                        </div>
                    </div>
                </QuestionCard>
            </TransitionGroup>
        </div>
    </div>
    <ConfirmationDialog ref="deleteOpenTextDialog">
        <template #icon>
            <div
                class="absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center w-16 aspect-square bg-[#FCDEEA] rounded-full"
            >
                <Icons.TrashIcon class="h-5 fill-[#E31266]" />
            </div>
        </template>
        <template #title>
            <p class="mt-5 mb-12">Are you sure you want to delete this question?</p>
        </template>
    </ConfirmationDialog>

    <ModalDialog :isOpen="!!openTextToUpdate" @update:isOpen="$event => $event === false && closeEditDialog()">
        <template #title>Edit question</template>
        <QuestionForm
            class="w-full mt-10"
            @submit.prevent="update"
            v-model:name="form.name"
            v-model:message="form.message"
            :errors="{
                name: form.errors.name && [form.errors.name],
                message: form.errors.message && [form.errors.message],
            }"
        >
            <div class="flex flex-wrap gap-2 items-center w-full">
                <button
                    class="btn btn--narrow grow text-[#ffff] bg-[#6212E5] hover:bg-[#7E39EF]"
                    type="submit"
                    :disabled="form.processing"
                    v-text="form.processing ? 'Updating...' : 'Update'"
                ></button>

                <button
                    class="btn btn--tertiary btn--narrow btn--outline grow"
                    v-text="'Cancel'"
                    @click.prevent="closeEditDialog"
                ></button>
            </div>
        </QuestionForm>
    </ModalDialog>
</template>
