<script setup>
import { useForm, usePage } from '@inertiajs/vue3';
import { useToast, POSITION } from 'vue-toastification';
import { QuestionForm, ActivePollPreview } from '../../Components';
import { useTvViewBackground } from '@/composables';
import { defaultTheme } from '@/Components/ThemePicker.vue';
import { Icons } from '@goodvibes/kudoslink';

/**
 * @type {{ meeting: Meeting, poll: Poll, responses: Array<WordCloud>, anonymousId: string, name: string }}
 */
const props = defineProps({
    meeting: {
        type: Object,
        required: true,
    },
    poll: {
        type: Object,
        required: true,
    },
    responses: {
        type: Array,
        required: true,
    },
    // Anonymous user identifier
    anonymousId: {
        type: String,
        required: true,
    },
    name: {
        type: String,
        required: false,
    },
});

const toast = useToast();

const form = useForm({
    word: '',
});

const { themeStyles, themeClasses } = useTvViewBackground(props.meeting.theme ?? defaultTheme);

function save() {
    form.post(route('responses.wordclouds.store', { uuid: props.meeting.uuid, pollId: props.poll.id }), {
        headers: {
            // this will help Laravel identify, who is the sender, and it will use that to not send the event to this user
            'X-Socket-ID': window.Echo.socketId(),
        },
        preserveScroll: true,
        onSuccess: () => {
            const { type = 'success', content = 'Word submitted!' } = usePage().props.flash.notification || {};
            toast[type](content, { position: POSITION.BOTTOM_CENTER });

            form.reset();
        },
    });
}
</script>

<template>
    <QuestionForm
        v-if="!poll.closedAt"
        @submit="save"
        :name="false"
        :characterLimit="25"
        v-model:message="form.word"
        :errors="{ message: form.errors.word && [form.errors.word] }"
        :messageInputAttrs="{ placeholder: 'Your word' }"
        v-slot="{ remainingCharacters }"
    >
        <div class="flex justify-center lg:justify-end">
            <button
                class="btn flex btn--narrow text-[#ffff] bg-[#6212E5]"
                type="submit"
                :disabled="form.processing || remainingCharacters < 0"
                v-text="form.processing ? 'Sending' : remainingCharacters < 0 ? 'Message is too long.' : 'Submit'"
            ></button>
        </div>
    </QuestionForm>
    <p v-else class="text-[#8C96A1] italic text-center">Voting was stopped.</p>

    <template v-if="poll.showResults">
        <div class="flex items-center text-[#8C96A1] font-semibold text-base leading-7 mt-12 mb-10">
            <h2>Live results</h2>
            <Icons.PersonCheckIcon class="ml-5 w-5 inline-block" />
            <span class="ml-2">{{ poll.responsesCount }}</span>
        </div>
        <div
            class="w-full aspect-video rounded-3xl overflow-hidden"
            :class="themeClasses"
            :style="{ ...themeStyles, backgroundAttachment: 'initial' }"
        >
            <ActivePollPreview
                :meeting="meeting"
                :activePoll="poll"
                :responses="responses"
                class="scale-[60%] -mx-48 -my-14"
            />
        </div>
    </template>
</template>
