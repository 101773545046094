<script setup>
import { ToggleInput, Icons, ModalDialog, PopperTooltip } from '@goodvibes/kudoslink';
import { useForm, router, usePage } from '@inertiajs/vue3';
import { PencilIcon, TrashIcon } from '@heroicons/vue/24/outline';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useToast, POSITION } from 'vue-toastification';
import Spinner from '@/Components/Spinner.vue';
import ConfirmationDialog from '@/Components/ConfirmationDialog.vue';
import { QuestionCard, QuestionForm } from '@/Components';
import { Question } from '@/models';
import { vInfiniteScroll } from '@vueuse/components';

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo('en-US');

/**
 * @type {{ meeting: Meeting, poll: Poll, questions: Array<Question>, anonymousId: string, name: string }}
 */
const props = defineProps({
    meeting: {
        type: Object,
        required: true,
    },
    poll: {
        type: Object,
        required: true,
    },
    questions: {
        type: Array,
        required: true,
    },
    // Anonymous user identifier
    anonymousId: {
        type: String,
        required: true,
    },
    name: {
        type: String,
        required: false,
    },
});

const emit = defineEmits(['loadMore']);

const toast = useToast();

const form = useForm({
    message: '',
    name: props.name,
    pollId: props.poll.id,
});

const params = new URLSearchParams(window.location.search);

const popularFirst = ref(params.has('sortBy') ? (params.get('sortBy') === 'upvotes' ? true : false) : true);

watch(
    () => popularFirst.value,
    () => {
        if (popularFirst.value === true) {
            router.get(route('meetings.userview', { uuid: props.meeting.uuid, sortBy: 'upvotes' }));
        } else {
            router.get(route('meetings.userview', { uuid: props.meeting.uuid, sortBy: 'created_at' }));
        }
    },
    { deep: true },
);

const questions = computed(() =>
    Question.sort(props.questions, popularFirst.value ? 'upvotes' : 'createdAt', 'desc', isHighlighted),
);

Echo.channel(`meeting.${props.meeting.uuid}`).listen('QuestionApproved', ({ question: q, approval }) => {
    const question = Question.format(q);
    if (question.anonymousId === props.anonymousId && approval) {
        toast.success(`Your question "${question.message.slice(0, 50)}..." was approved`, {
            position: POSITION.BOTTOM_CENTER,
        });
    }
});

const questionToUpdate = ref();
function openEditDialog(question) {
    const q = props.questions.find(q => q.id === question.id);
    form.message = q.message;
    form.name = q.name;
    questionToUpdate.value = question;
}

function closeEditDialog() {
    form.reset();
    questionToUpdate.value = null;
}

function save() {
    form.post(route('responses.questions.store', { uuid: props.meeting.uuid, pollId: props.poll.id }), {
        headers: {
            // this will help Laravel identify, who is the sender, and it will use that to not send the event to this user
            'X-Socket-ID': window.Echo.socketId(),
        },
        preserveScroll: true,
        onSuccess: () => {
            const { type = 'success', content = 'Question successfully sent!' } =
                usePage().props.flash.notification || {};
            toast[type](content, { position: POSITION.BOTTOM_CENTER });
            form.reset('message');
        },
    });
}

function update() {
    form.patch(
        route('responses.questions.update', {
            uuid: props.meeting.uuid,
            pollId: props.poll.id,
            question: questionToUpdate.value.id,
        }),
        {
            preserveScroll: true,
            onSuccess: () => {
                questionToUpdate.value = null;
                const { type = 'success', content = 'Question updated!' } = usePage().props.flash.notification || {};
                toast[type](content, { position: POSITION.BOTTOM_CENTER });
                form.reset('message');
            },
        },
    );
}

function isHighlighted(question) {
    return props.poll.highlightedResponseId === question.id;
}

const voting = ref();
function upvote(question) {
    voting.value = question;
    router.post(
        route('responses.questions.upvote', {
            uuid: props.meeting.uuid,
            questionId: question.id,
            pollId: props.poll.id,
        }),
        undefined,
        {
            preserveScroll: true,
            onError: e => {
                if (e.questionId) {
                    alert(e.questionId);
                }
            },
            onFinish: () => (voting.value = null),
        },
    );
}

function removeUpvote(question) {
    voting.value = question;
    router.delete(
        route('responses.questions.removeUpvote', {
            uuid: props.meeting.uuid,
            questionId: question.id,
            pollId: props.poll.id,
        }),
        {
            preserveScroll: true,
            onFinish: () => (voting.value = null),
        },
    );
}

const deleteQuestionDialog = ref();

async function deleteQuestion(question) {
    const { confirmed } = await deleteQuestionDialog.value.open();
    if (!confirmed) {
        return;
    }

    router.delete(
        route('responses.questions.destroy', {
            uuid: props.meeting.uuid,
            questionId: question.id,
            pollId: props.poll.id,
        }),
        {
            preserveScroll: true,
            // onSuccess: () => alert('upvote removed!'),
        },
    );
}

// make the time ticking
let rafId;
const currentTime = ref(new Date());

const updateTime = () => {
    currentTime.value = new Date();
    rafId = requestAnimationFrame(updateTime);
};

onMounted(() => {
    rafId = requestAnimationFrame(updateTime);
});

onUnmounted(() => {
    if (rafId) {
        cancelAnimationFrame(rafId);
    }
});

function canEdit(question) {
    const fiveMinAgo = new Date(currentTime.value).setMinutes(currentTime.value.getMinutes() - 5);
    return (
        question.anonymousId === props.anonymousId &&
        question.createdAt > fiveMinAgo &&
        !isHighlighted(question) &&
        !question.approvedAt
    );
}

function canDelete(question) {
    return canEdit(question);
}

function onLoadMore() {
    emit('loadMore');
}
</script>

<template>
    <QuestionForm
        v-if="!poll.closedAt"
        @submit="save"
        v-model:name="form.name"
        v-model:message="form.message"
        :errors="{
            name: form.errors.name && [form.errors.name],
            message: form.errors.message && [form.errors.message],
        }"
    >
        <div class="flex justify-center lg:justify-end">
            <button
                class="btn flex btn--narrow text-[#ffff] bg-[#6212E5] hover:bg-[#7E39EF]"
                type="submit"
                :disabled="form.processing"
                v-text="form.processing ? 'Sending' : 'Submit'"
            ></button>
        </div>
    </QuestionForm>

    <p v-else class="text-[#8C96A1] italic text-center">Q&A was stopped.</p>
    <div class="questions mt-20">
        <div class="flex items-center gap-x-5">
            <div class="flex items-center text-[#8C96A1] font-semibold text-base leading-7 mt-12 mb-10">
                <h2>Audience questions</h2>
                <Icons.PersonCheckIcon class="ml-5 w-5 inline-block" />
                <span class="ml-2">{{ poll.responsesCount }}</span>
            </div>
            <div class="h-[1px] grow bg-[#E4EDF5]"></div>
            <div class="flex items-center gap-x-4">
                <p class="text-[#8C96A1] font-bold uppercase">Recent</p>
                <ToggleInput v-model="popularFirst" :switchClass="['bg-[#6212E5] text-[#1212E0] h-7']">
                    <template #icon><span> </span></template>
                </ToggleInput>
                <p class="text-[#8C96A1] font-bold uppercase">Popular</p>
            </div>
        </div>

        <div class="max-h-96 overflow-y-scroll" v-infinite-scroll="[onLoadMore, { distance: 5 }]">
            <TransitionGroup name="list">
                <QuestionCard
                    v-for="question in questions"
                    :key="question.id"
                    :author="question.name"
                    class="mt-10 transition-all"
                    :class="{
                        'shadow-xl shadow-[#1212E0]/10 !ring-2 !ring-[#1212E0]/30 scale-105': isHighlighted(question),
                    }"
                >
                    <template #actions>
                        <PopperTooltip>
                            <div
                                class="h-10 p-3 border rounded-xl flex items-center justify-between text-xs transition-colors duration-300 ease-in-out"
                                :class="[
                                    Question.isUpvotedBy(question, anonymousId)
                                        ? 'text-[#6212E5] bg-[#EDEDFC] border-transparent'
                                        : 'text-[#BBC4CD] bg-white border-[#EDEDFC]',
                                    voting?.id === question.id
                                        ? 'pointer-events-none cursor-default'
                                        : 'cursor-pointer',
                                ]"
                                @click="
                                    Question.isUpvotedBy(question, anonymousId)
                                        ? removeUpvote(question)
                                        : upvote(question)
                                "
                            >
                                <Spinner v-if="voting?.id === question.id" class="h-full animate-spin" />
                                <div v-else class="flex items-center gap-x-2">
                                    <small
                                        v-show="question.upvotes.length"
                                        class="text-xs"
                                        v-text="question.upvotes.length"
                                    ></small>
                                    <Icons.ThumbUpIcon
                                        class="inline-block h-4 stroke-current transition duration-300"
                                    />
                                </div>
                            </div>
                            <template #popper>{{
                                Question.isUpvotedBy(question, anonymousId) ? 'Remove upvote' : 'Upvote'
                            }}</template>
                        </PopperTooltip>

                        <PopperTooltip v-if="canEdit(question)" placement="top">
                            <div
                                class="h-10 p-3 cursor-pointer bg-[#EDEDFC] text-[#6212E5] rounded-xl flex items-center justify-between text-xs"
                                @click="openEditDialog(question)"
                            >
                                <PencilIcon class="h-4 stroke-current transition duration-300" />
                            </div>
                            <template #popper>Edit</template>
                        </PopperTooltip>

                        <PopperTooltip v-if="canDelete(question)" placement="top">
                            <div
                                class="h-10 p-3 cursor-pointer bg-[#EDEDFC] text-[#6212E5] rounded-xl flex items-center justify-between text-xs"
                                @click="deleteQuestion(question)"
                            >
                                <TrashIcon class="h-4 stroke-current transition duration-300" />
                            </div>
                            <template #popper>Delete</template>
                        </PopperTooltip>
                    </template>

                    <p v-text="question.message"></p>

                    <template #footer>
                        <small
                            v-if="poll.settings.requiresApproval && !question.approvedAt"
                            class="text-xs px-2 py-1 rounded-lg bg-amber-200 text-amber-800"
                            >Approval pending
                        </small>
                        <small class="ml-2 text-xs text-[#8C96A1]" v-text="timeAgo.format(question.createdAt)"></small>
                    </template>
                </QuestionCard>
            </TransitionGroup>
        </div>
    </div>
    <ConfirmationDialog ref="deleteQuestionDialog">
        <template #icon>
            <div
                class="absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center w-16 aspect-square bg-[#FCDEEA] rounded-full"
            >
                <Icons.TrashIcon class="h-5 fill-[#E31266]" />
            </div>
        </template>
        <template #title>
            <p class="mt-5 mb-12">Are you sure you want to delete this question?</p>
        </template>
    </ConfirmationDialog>

    <ModalDialog :isOpen="!!questionToUpdate" @update:isOpen="$event => $event === false && closeEditDialog()">
        <template #title>Edit question</template>
        <QuestionForm
            class="w-full mt-10"
            @submit.prevent="update"
            v-model:name="form.name"
            v-model:message="form.message"
            :errors="{
                name: form.errors.name && [form.errors.name],
                message: form.errors.message && [form.errors.message],
            }"
        >
            <div class="flex flex-wrap gap-2 items-center w-full">
                <button
                    class="btn btn--narrow grow text-[#ffff] bg-[#6212E5] hover:bg-[#7E39EF]"
                    type="submit"
                    :disabled="form.processing"
                    v-text="form.processing ? 'Updating...' : 'Update'"
                ></button>

                <button
                    class="btn btn--tertiary btn--narrow btn--outline grow"
                    v-text="'Cancel'"
                    @click.prevent="closeEditDialog"
                ></button>
            </div>
        </QuestionForm>
    </ModalDialog>
</template>
