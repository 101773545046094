<script setup>
// something what listen on socket and redirect user to correct form
import { Head, router } from '@inertiajs/vue3';
import feedbeoLogo from '../../images/logo.svg';
import { useMeeting } from '@/composables';
import { QAForm, RatingForm, MultipleChoiceForm, WordCloudForm, OpenTextForm, RankingForm } from '@/Components/Forms';
import { PollTypes } from '@/shared';
import { KudoCard } from '@goodvibes/kudoslink';
import { Response } from '@/models';
import { ref } from 'vue';
import { useInfiniteLoading } from '@/composables';

/**
 * @type {{ meeting: Meeting, activePoll: Poll, responses: Array<PollResponse>, anonymousId: string, name: string }}
 */
const props = defineProps({
    meeting: {
        type: Object,
        required: true,
    },
    activePoll: {
        type: Object,
        required: false,
    },
    responses: {
        type: Array,
        required: false,
    },
    // Anonymous user identifier
    anonymousId: {
        type: String,
        required: true,
    },
    name: {
        type: String,
        required: false,
    },
});

const { meeting, activePoll, responses } = useMeeting(props, {
    onPollStarted() {
        router.reload();
    },
});

const params = new URLSearchParams(window.location.search);

let totalResponses = null;

const infiniteLoader = useInfiniteLoading();

async function handleInfiniteLoad() {
    if (totalResponses != null && responses.value.length >= totalResponses) return;
    if (infiniteLoader.isLoading.value === true) return;

    const data = await infiniteLoader.load(
        route('meetings.userview', {
            uuid: meeting.value.uuid,
            skip: responses.value.length,
            sortBy: params.has('sortBy') ? params.get('sortBy') : 'upvotes',
        }),
    );
    let { responses: newResponses, total } = data;
    totalResponses = total;
    newResponses = newResponses.map(response => Response.format(response, activePoll.value.type));
    responses.value.push(...newResponses);
}
</script>

<template>
    <Head>
        <meta name="robots" content="noindex,nofollow" />
        <title>FeedBeo | Join to vote and ask question</title>
    </Head>

    <div class="bg-[#F8F9FF] min-h-screen py-4">
        <div class="max-w-5xl px-4 mx-auto">
            <nav class="py-10 flex flex-col justify-center text-center">
                <img
                    class="mb-1 h-24 aspect-[16/9] object-contain"
                    v-if="meeting.logo"
                    :src="meeting.logo"
                    alt="meeting logo"
                />
                <h1 class="font-sans text-xl leading-[4rem] text-[#3F4A55] font-bold">
                    {{ meeting.name }}
                </h1>
            </nav>

            <KudoCard v-if="!activePoll" class="animate-pulse">
                <div class="text-center animate-pulsez">Wait for moderator</div>
            </KudoCard>
            <template v-else>
                <div class="mb-10">
                    <div v-if="activePoll.topic" class="text-[#3F4A55] font-semibold text-2xl leading-9">
                        {{ activePoll.topic }}
                    </div>
                    <div v-if="activePoll.description" class="text-[#8C96A1] font-semibold text-base leading-7">
                        {{ activePoll.description }}
                    </div>
                </div>
                <QAForm
                    v-if="activePoll.type === PollTypes.QA"
                    :meeting="meeting"
                    :poll="activePoll"
                    :questions="responses"
                    :anonymousId="anonymousId"
                    :name="name"
                    @loadMore="handleInfiniteLoad"
                />

                <OpenTextForm
                    v-if="activePoll.type === PollTypes.OPEN_TEXT"
                    :meeting="meeting"
                    :poll="activePoll"
                    :openTexts="responses"
                    :anonymousId="anonymousId"
                    :name="name"
                    @loadMore="handleInfiniteLoad"
                />

                <RatingForm
                    v-else-if="activePoll.type === PollTypes.RATING"
                    :meeting="meeting"
                    :poll="activePoll"
                    :responses="responses"
                    :anonymousId="anonymousId"
                    :name="name"
                />

                <MultipleChoiceForm
                    v-else-if="activePoll.type === PollTypes.MULTIPLE_CHOICE"
                    :meeting="meeting"
                    :poll="activePoll"
                    :responses="responses"
                    :anonymousId="anonymousId"
                    :name="name"
                />

                <RankingForm
                    v-else-if="activePoll.type === PollTypes.RANKING"
                    :meeting="meeting"
                    :poll="activePoll"
                    :responses="responses"
                    :anonymousId="anonymousId"
                    :name="name"
                />

                <WordCloudForm
                    v-else-if="activePoll.type === PollTypes.WORD_CLOUD"
                    :meeting="meeting"
                    :poll="activePoll"
                    :responses="responses"
                    :anonymousId="anonymousId"
                    :name="name"
                />
            </template>

            <footer class="mb-5 mt-20">
                <img class="mx-auto" width="116" height="32" :src="feedbeoLogo" alt="feedbeo logo" />
            </footer>
        </div>
    </div>
</template>
